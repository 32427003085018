.rest-calendar .rw-calendar .rw-header .rw-btn-left {
  visibility: hidden;
}

.rest-calendar .rw-calendar .rw-header .rw-btn-right {
  visibility: hidden;
}

.rest-calendar .rw-calendar .rw-header .rw-btn-view {
  background-color: white;
}

.rest-calendar .rw-state-focus {
  border: 0;
}

.rest-calendar .rw-calendar-grid.rw-nav-view .rw-btn {
  padding: 0;
}

.rest-calendar .rw-calendar-grid td .rw-btn {
  padding: 0;
}

.rest-calendar .rw-widget.rw-state-readonly {
  cursor: default;
}

.rest-calendar .rw-calendar {
  margin-bottom: 5px;
}

.month-summary {
  text-align: center;
  font-weight: bold;
  margin-bottom: 30px;
}

.rest-calendar * {
  opacity: 1 !important;
}

.rest-calendar .rw-calendar div.center-cell {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rest-calendar .rw-calendar div.holiday {
  background-color: #ccffcc;
  border-radius: 5px;
}

.rest-calendar .rw-calendar .rw-cell {
  padding: 0;
  line-height: inherit;
}

.rest-calendar .rw-calendar .rw-cell span {
  display: block;
  height: 100%;
}

.rest-calendar .rw-calendar-btn-left {
  display: none;
}

.rest-calendar .rw-calendar-btn-right {
  display: none;
}

.rest-calendar .rw-calendar-btn-today {
  display: none;
}

.rest-calendar .rw-calendar-header {
  justify-content: center;
}

.rest-calendar .rw-calendar-btn-view svg {
  display: none;
}
