.pln-load-save-toolbar {
  display: inline-block;
  text-align: center;
  width: 100%;
}

.pln-load-save-toolbar button {
  margin-bottom: 8px;
}

.pln-load-save-toolbar #pln-load-save-del {
  float: right !important;
}
