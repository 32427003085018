.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.spinner {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
}

@media (min-width: 768px) {
  .form-inline .rw-datetimepicker {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
}

.form-inline > * {
  margin: 5px 3px;
}

.navbar-success a {
  color: #3c763d !important;
  font-weight: bold;
}

.inline-date-picker .rw-date-picker {
  width: 200px;
  display: inline-block;
}