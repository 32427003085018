body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.fb-like-container {
  position: relative;
  padding: 0;
  padding-top: 55px;
}
.fb-like {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.footer {
  background-color: #f2f2f2;
  color: #e9e9e9;
  padding: 5px 10px;
  border-radius: 5px;
}
.footer a {
  color: #333;
}
.footer a:hover {
  color: #333;
  text-decoration: underline;
}
.footer button {
  color: #333;
  font-size: 12px;
}
.footer-links {
  padding-left: 15px;
  color: #333;
}
.footer-copyright {
  text-align: center;
}
